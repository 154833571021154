



















































































/**
 *Single project view,
 The interval to get the project sections state is set default to 30s for regular
 project and CWU projects 10s,
 The get sections state is in the map component.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [ ] Update the typescript.
 * @Todo [ ] Implement webSockets to get actions  in all the sections state change .
 */
// GetUserInTheProject
import { Component, Vue } from "vue-property-decorator";
import { ActionMethod, mapActions, mapGetters, mapMutations } from "vuex";

import Map from "@/components/project/SingleProject/Map/Map.vue";
import SchematicSections from "@/components/project/SingleProject/Schematic/SchematicSections.vue";
import UpdateProjectForm from "@/components/project/SingleProject/UpdateProjectForm.vue";
import UpdateThreshold from "@/components/project/SingleProject/Schematic/ThresHold/UpdateThreshold.vue";
import SectionList from "@/components/project/SingleProject/Sections/SectionList.vue";
import CreateSectionForm from "@/components/project/SingleProject/Sections/CreateSectionForm.vue";
import UserTabView from "@/components/project/SingleProject/Users/UserTabView.vue";
import DeviceListTabView from "@/components/project/SingleProject/Device/DeviceListTabView.vue";
import BatteryListTabView from "@/components/project/SingleProject/Battery/BatteryListTabView.vue";
import ProjectProfilesTabView from "@/components/project/SingleProject/Profiles/ProjectProfilesTabView.vue";
import ProjectSceneTabView from "@/components/project/SingleProject/Scene/ProjectSceneTabView.vue";
import UnAuthorizedView from "@/components/shared/UnAuthorizedView.vue";

import Project from "@/model/Project";
import Section from "@/model/ModelSection";
import { AxiosResponse } from "axios";
import { Route } from "vue-router";
import UserAccess from "@/model/User/UserAccess";
import { ActionMessage } from "@/model/Messages/statusMessage";

@Component({
  name: "SingleProject",
  components: {
    SectionList,
    Map,
    SchematicSections,
    UpdateProjectForm,
    UpdateThreshold,
    CreateSectionForm,
    UserTabView,
    DeviceListTabView,
    BatteryListTabView,
    ProjectProfilesTabView,
    ProjectSceneTabView,
    UnAuthorizedView
  },
  computed: {
    ...mapGetters(["isUserAdmin", "isUserAgency", "isUserProjectManager", "isConstructionManager", "getUserAccess"]),
    ...mapGetters("projects", {
      GetProjectById: "GetProjectById",
      GetCurrentProject: "GetCurrentProject",
      GetCanViewProject: "GetCurrentUserViewProjectDetails",
      // GetAllUsersInTheProject: "GetAllUsersInTheProject",
      CurrentProjectSectionList: "GetAllSectionInProject"
    })
  },
  methods: {
    ...mapMutations("projects", {
      resetProject: "ResetProject",
      resetSectionsList: "ResetSectionList"
    }),
    ...mapActions("projects", {
      DetailsOfProject: "DetailsOfProject",
      getAllSectionFromTheProject: "GetAllSectionFromTheProject",
      createNewSectionInTheProject: "CreateNewSection",
      DeleteSection: "DeleteSectionInTheProject",
      getAllDisplayTypes: "GetAllDisplayTypesFromTheBackEnd"
    }),
    ...mapActions("Evaluator", {
      FETCH_CURRENT_PROJECT_VOLUME: "FETCH_CURRENT_PROJECT_VOLUME"
    })
  },
  async beforeRouteLeave(to: Route, from: Route, next: any): Promise<any> {
    await (this as any).clearAllIntervalsBeforeLeave();
    (this as any).resetProject();

    next();
  }
})
export default class SingleProject extends Vue {
  // Vuex
  readonly isUserAdmin!: boolean;
  readonly isUserAgency!: boolean;
  readonly isUserProjectManager!: boolean;
  readonly isConstructionManager!: boolean;
  readonly GetCanViewProject!: boolean;
  readonly getUserAccess!: UserAccess;
  readonly GetProjectById!: number;
  readonly GetCurrentProject!: Project;
  readonly CurrentProjectSectionList!: Array<Section>;

  private FETCH_CURRENT_PROJECT_VOLUME!: ActionMethod;

  private resetProject!: Function;
  private resetSectionsList!: Function;
  private DetailsOfProject!: Function;
  private getAllSectionFromTheProject!: Function;
  private createNewSectionInTheProject!: Function;
  private DeleteSection!: Function;
  private getAllDisplayTypes!: Function;

  // Data
  intervalUpdateProjectData: Array<any> = [];
  updateStatus: null | ActionMessage = null;
  items: Array<object> = [];
  userInTheProject: null | Array<string> = null;
  staticSections: Array<object> = [];

  loading = false;
  setViewProject: boolean = true;

  /*----------  Vue lifecycles  ----------*/
  created() {
    this.loading = true;

    this.DetailsOfProject(this.$route.params.id)
      .then(async (status: AxiosResponse) => {
        if (status.status === 200) {
          await this.getProjectInformationEveryMinute();
          await this.getAllSectionFromTheProject(this.$route.params.id);
          await this.FETCH_CURRENT_PROJECT_VOLUME(this.$route.params.id);
          this.getAllDisplayTypes();
          this.loading = false;

          if( (!this.isUserAdmin && !this.isUserProjectManager && !this.isConstructionManager) && !this.GetCanViewProject) {
            this.setViewProject = false
          } else {
            this.setViewProject = true;
          }

        } else if (status.status !== 200) {
          this.loading = false;
          this.updateStatus = {
            class: "danger",
            msg: !this.GetCanViewProject ? this.$t("project.access.denied.title") : (status as unknown as string)
          };
        }
      })
      .catch((err: unknown) => {
        this.loading = false;
        this.updateStatus = {
          class: "danger",
          msg: err as unknown as string
        };
      });
  }

  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent: any, modalId: string) => {
      if (modalId === "createSectionForm") {
        this.updateStatus = null;
      }
    });
  }

  /*---------- computed  ----------*/

  confirmModal(msg: string, action: Function) {
    this.$bvModal
      .msgBoxConfirm(msg, {
        title: (this as any).$t("projects.singleProjectView.confirmationModal.title"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          action();
        }
      });
  }

  removeSectionFromProject(section: Section) {
    this.updateStatus = null;
    this.confirmModal(
      (this as any).$t("projects.singleProjectView.confirmationModal.body", {
        name: section.title
      }),
      () => {
        this.DeleteSection(section.id).then((status: number) => {
          if (status === 204) {
            this.getAllSectionFromTheProject(this.$route.params.id);
            this.updateStatus = {
              class: "success",
              msg: this.$t("projects.singleProjectView.statusMsg.removeSectionSuccess")
            };
          } else {
            this.updateStatus = {
              class: "danger",
              msg: status as unknown as string
            };
          }
        });
      }
    );
  }

  async createNewSection(newSectionData: Section) {
    this.updateStatus = null;
    try {
      const res = await this.createNewSectionInTheProject(newSectionData);
      if (res.status === 201) {
        this.$bvModal.hide("createSectionForm");
        this.staticSections = this.CurrentProjectSectionList;
        this.updateStatus = {
          class: "success",
          msg: this.$t("projects.singleProjectView.statusMsg.createdSectionSuccess")
        };
      } else {
        this.updateStatus = {
          class: "danger",
          msg: this.$t(res)
        };
      }
    } catch (error) {
      this.updateStatus = {
        class: "danger",
        msg: this.$t("projects.singleProjectView.statusMsg.error")
      };
    }
  }

  updateSectionList(sectionListUpdated: Array<object>) {
    this.staticSections = sectionListUpdated;
  }

  updateStatusMsg(value: ActionMessage) {
    this.updateStatus = null;
    this.updateStatus = value;
  }

  private async clearAllIntervalsBeforeLeave() {
    console.log("######## Clear interval ######");
    let promises = this.intervalUpdateProjectData.map(async (intervalId: number) => {
      await clearInterval(intervalId);
    });
    await Promise.all(promises);
  }

  async getProjectInformationEveryMinute() {
    // Get information of the project  every minute from the back end
    if (this.GetCurrentProject.isArchived) return;

    let GET_PROJECT_INFORMATION = 30 * 1000;
    if (this.GetCurrentProject.isCWU) {
      GET_PROJECT_INFORMATION = 10 * 1000;
    }
    let newInterval = setInterval(async () => {
      console.log("### INTERVAL IS RUNNING #####");
      if (this.$route.params.id) {
        await this.getAllSectionFromTheProject(this.$route.params.id);
        await this.FETCH_CURRENT_PROJECT_VOLUME(this.$route.params.id);
      } else {
        await this.clearAllIntervalsBeforeLeave();
      }
    }, GET_PROJECT_INFORMATION);

    this.intervalUpdateProjectData.push(newInterval);
  }
}
